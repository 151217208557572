(function () {
    'use strict';

    function main() {

        var home = wo$('#home');
        if (!home.length) return;

        var controller = {

            // eslint-disable-next-line @typescript-eslint/no-empty-function
            init: function () {
            }

        };

        controller.init();
    };

    WhistleOut.initModule(import.meta.url, main);

}());
